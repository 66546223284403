import React from "react";
import SEO from "../components/seo";

function NotFoundPage() {
  return (
    <>
      <SEO title="404: Nicht gefunden" />
      <div className="min-h-screen flex flex-col items-center align-center justify-center text-fold">
        <h1 className="text-5xl">Nicht gefunden</h1>
        <p>Sie haben eine nicht existierende Seite angewählt...</p>
      </div>
    </>
  );
}

export default NotFoundPage;
